<template>
  <div class="pledge">
    <div class="header" id="headerPc">
      <div style="display: flex" v-if="decipherment">
        <img
          @click="index"
          class="logo"
          src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/logo.png"
          alt=""
        />
        <div class="title engreg" @click="index" style="margin-left: 60px">
          HOME
        </div>
        <div class="title engreg" @click="party">PARTY</div>
        <div class="title engreg" @click="refresh">STAKING</div>
        <div class="title engreg" @click="world">GAMBLER HALL</div>
        <!-- <div class="title engreg" @click="changeCloth">World Cup Outfits</div> -->
        <div class="title engreg" @click="starry">3D PARTY</div>
      </div>
      <div style="display: flex" v-else>
        <img
          @click="index"
          class="logo"
          src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/logo.png"
          alt=""
        />
        <div class="title chinreg" style="margin-left: 60px" @click="index">
          首页
        </div>
        <div class="title chinreg" @click="party">PARTY</div>
        <div class="title chinreg" @click="refresh">质押</div>
        <div class="title chinreg" @click="world">世界杯赌王</div>
        <!-- <div class="title chinreg" @click="changeCloth">世界杯换装</div> -->
        <div class="title chinreg" @click="starry">3D PARTY</div>
      </div>
      <ul class="header_right">
        <li class="connect" v-if="connected">
          <div class="hover_box1 engBold">
            {{ walletAddress }}
          </div>
        </li>
        <li class="connect1" v-else @click="logIn">
          <p class="engBold" v-if="decipherment">CONNECT WALLET</p>
          <p class="chinBold headermar" v-else>连接钱包</p>
        </li>
        <li
          class="title1"
          @click="revise"
          :class="decipherment ? 'engreg' : 'chinreg'"
        >
          {{ eng }}
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/xia.png"
            alt=""
          />
          <div class="cha_text" v-show="revise_text" @click="deciphermented">
            {{ chin }}
          </div>
        </li>
        <li class="img1" @click="openseaview">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/opensea1.png"
            alt=""
            srcset=""
          />
        </li>
        <li class="img">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/twitter1.png"
            alt=""
            srcset=""
            @click="twitter"
          />
        </li>
        <li class="img">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/redirect.png"
            alt=""
            srcset=""
            @click="redirect"
          />
        </li>
      </ul>
    </div>
    <div class="headerM" id="headerMM">
      <div>
        <img
          @click="index"
          class="logo"
          src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/logo.png"
          alt=""
        />
      </div>
      <div class="headerM_ul">
        <div class="butbox">
          <div class="connect" v-if="connected">
            <div class="hover_box1 engBold">
              {{ walletAddress }}
            </div>
          </div>
          <div class="connect1" v-else @click="logIn">
            <p class="engBold" v-if="decipherment">CONNECT</p>
            <p class="chinBold" v-else>连接钱包</p>
          </div>
        </div>
        <div class="menubox" @click="menu">
          <img
            class="menu"
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/menu.png"
            alt=""
          />
          <div class="del" v-if="menudel">
            <div v-if="decipherment">
              <p class="text engreg" @click="index">HOME</p>
              <p class="text engreg" @click="party">PARTY</p>
              <p class="text engreg" @click="refresh">STAKING</p>
              <p class="text engreg" @click="world">GAMBLE</p>
              <!-- <p class="text engreg" @click="changeCloth">OUTFITS</p> -->
              <p class="text engreg" @click="starry">3D PARTY</p>
              <p class="text engreg" @click="openseaview">
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/openseaM.png"
                  alt=""
                  srcset=""
                />
                <span>OPENSEA</span>
              </p>
              <p class="text engreg" @click="twitter">
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/twitterM.png"
                  alt=""
                  srcset=""
                />
                <span>TWITTER</span>
              </p>
              <p class="text engreg" @click="redirect">
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/redirect.png"
                  alt=""
                  srcset=""
                />
                <span>DISCORD</span>
              </p>
              <p class="text engreg" @click="deciphermented">中文</p>
            </div>
            <div v-else>
              <p class="text chinreg" @click="index">首页</p>
              <p class="text chinreg" @click="party">PARTY</p>
              <p class="text chinreg" @click="refresh">质押</p>
              <p class="text chinreg" @click="world">世界杯赌王</p>
              <!-- <p class="text chinreg" @click="changeCloth">世界杯换装</p> -->
              <p class="text chinreg" @click="starry">3D PARTY</p>
              <p class="text engreg" @click="openseaview">
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/openseaM.png"
                  alt=""
                  srcset=""
                />
                <span>OPENSEA</span>
              </p>
              <p class="text engreg" @click="twitter">
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/twitterM.png"
                  alt=""
                  srcset=""
                />
                <span>TWITTER</span>
              </p>
              <p class="text engreg" @click="redirect">
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/redirect.png"
                  alt=""
                  srcset=""
                />
                <span>DISCORD</span>
              </p>
              <p class="text chinreg" @click="deciphermented">ENGLISH</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="banner">
      <p>
        <img
          class="banner_img"
          src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/banner.png"
          alt=""
          srcset=""
        />
      </p>
      <div class="banner_text engreg">
        <p v-if="decipherment" class="engreg">
          As the magic bus flies across the starry sky, welcome to the
          Rock³verse, the great utopia of freedom, Rock 'n' Roll, and unchained
          drunkies.
        </p>
        <p v-else class="chinreg">
          你们滚青啊，真是了不得。那晚，你们推开公共时光大巴的门，涌入Rock³verse，瞬间开启夜夜笙歌模式！喝酒，狂欢，跟别人睡觉，全年无休！
        </p>
      </div>
      <div class="banner_text engreg">
        <p v-if="decipherment" class="engreg">
          In the Rock³verse, every drunkie knows the magic bus will only provide
          service for ONE time... If you want to travel to another verse in the
          future, you gotta make
          <span style="color: #3395a0; font-weight: bold"> your own bus</span>.
        </p>
        <p v-else class="chinreg">
          只有贪婪的Bot Marley一直在工作，他为东倒西歪的醉汉们<span
            style="color: #3395a0; font-weight: bold"
            >组装</span
          >更厉害的时光大巴醉汉们都知道，公共时光大巴不会为一位乘客提供第二次服务；下一趟时空旅行，就得靠自己的时光大巴了。为了方便你勾搭更美/更帅的人一起睡觉，Bot
          Marley甚至为你准备了更屌的涂装、更炫的装备、甚至更帅的司机。但你得多囤点啤酒瓶盖！妈的，Bot
          Marley黑心！
        </p>
      </div>
      <div class="banner_text engreg">
        <p v-if="decipherment" class="engreg">
          The good news is, if you drink enough and collect enough Beer Caps,
          Bot Marley will make you a
          <span style="color: #3395a0; font-weight: bold"> time machine</span>
          (the bus, duh) with your crowns of merits. A halo headlight, a LEGO
          body, even a legendary driver?? Everything can be
          <span style="color: #3395a0; font-weight: bold"> customized</span>!
          You just need to collect more Beer Caps. Aside from the time machine,
          there are many other things you can do with the Beer Caps in the
          Rock³verse. So don't throw them away!
        </p>
        <p v-else class="chinreg">
          但Bot Marley瞧不起清醒的人，你得把灵魂<span
            style="color: #3395a0; font-weight: bold"
            >质押</span
          >给酒神，Bot Marley才会为你服务。在Rock³verse，他们管这种行为叫<span
            style="color: #3395a0; font-weight: bold"
            >“醉酒”</span
          >。每个醉酒的7天里，Bot Marley都会收集起你扔掉的<span
            style="color: #3395a0; font-weight: bold"
            >100个瓶盖</span
          >，你的超级定制时光大巴就靠这些瓶盖了！Rock³verse的<span
            style="color: #3395a0; font-weight: bold"
            >更多摇滚设施</span
          >，也得靠这些瓶盖了！
        </p>
      </div>
      <div class="banner_text engreg">
        <p v-if="decipherment" class="engreg">
          Every 7 days, you'll chug down 100 beers and thus save
          <span style="color: #3395a0; font-weight: bold">100 Beer Caps</span>.
          For Pass Holders, you can have
          <span style="color: #3395a0; font-weight: bold">another bottle</span>
          for each beer you drink. Be careful! If you choose to get sober, you
          will lose all your Beer Caps!
        </p>
        <p v-else class="chinreg">
          突然想戒酒<span style="color: #3395a0; font-weight: bold">赎回</span
          >你的灵魂？黑心的Bot
          Marley会瞬间吃掉这个灵魂的所有瓶盖，清醒的灵魂注定<span
            style="color: #3395a0; font-weight: bold"
            >两手空空</span
          >！
        </p>
      </div>
      <div class="banner_text engreg">
        <p v-if="decipherment" class="engreg">
          Here, booze is optimal, pants are optional. Have fun, rockers!
        </p>
        <p v-else class="chinreg">
          嗝，OHDAT Genesis Pass持有者是最开心的。再来一瓶，
          <span style="color: #3395a0; font-weight: bold">双倍瓶盖</span
          >，双倍快乐！
        </p>
      </div>
    </div>
    <div>
      <div class="hengfu engBold" v-if="connected">
        <div class="hengfu_center">
          <div class="hengfu_left">
            <div class="hi">
              <img
                class="hi"
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/hi.png"
                alt=""
                srcset=""
                v-if="decipherment"
              />
              <img
                class="hi1"
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/hi1.png"
                alt=""
                srcset=""
                v-else
              />
            </div>
            <div class="adress engreg">{{ walletAddress }}</div>
          </div>
          <div class="xian"></div>
          <div class="hengfu_right">
            <img
              class="xingxing"
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/xing.png"
              alt=""
              srcset=""
            />
            <div class="hengfu_rightli">
              <p class="text engBold">{{ staked_count }}</p>
              <div class="title engreg">
                <p v-if="decipherment" class="engreg">Drunk Monsters</p>
                <p v-else class="chinreg">只怪兽醉酒中</p>
              </div>
            </div>
            <div class="hengfu_rightli">
              <div class="text hintbox engBold">
                <span>X{{ ratio }}</span>
                <img
                  @click="hint"
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/wenhao.png"
                  alt=""
                />
                <div class="hint">
                  <div class="hint_text engreg">
                    <p v-if="decipherment" class="engreg">
                      2X ratio for OHDAT <br />
                      GENESIS PASS holders
                    </p>
                    <p v-else class="chinreg">
                      尊贵的OHDAT GENESIS PASS <br />
                      holder！再来一瓶，双倍瓶盖！
                    </p>
                  </div>
                </div>
                <div class="hint1" v-show="hinttr">
                  <div class="hint_text engreg">
                    <p v-if="decipherment" class="engreg">
                      2X ratio for OHDAT <br />
                      GENESIS PASS holders
                    </p>
                    <p v-else class="chinreg">
                      尊贵的OHDAT GENESIS PASS <br />
                      holder！再来一瓶，双倍瓶盖！
                    </p>
                  </div>
                </div>
              </div>
              <div class="title engreg">Buff</div>
            </div>
            <div class="hengfu_rightli">
              <p class="text engBold">{{ rate }}</p>
              <div class="title engreg">
                <p v-if="decipherment" class="engreg">Beer Caps/Week</p>
                <p v-else class="chinreg">枚瓶盖每周</p>
              </div>
            </div>
            <div class="hengfu_rightli">
              <p class="text1 engBold">{{ beer_caps }}</p>
              <div class="title1 engreg">
                <p v-if="decipherment" class="engreg">Beer Caps in Total</p>
                <p v-else class="chinreg">枚瓶盖已进兜儿</p>
              </div>
              <p class="transfer" @click="thansfer">
                <span v-if="decipherment" class="engBold">WEDDING GIFT</span>
                <span v-else class="chinBold">去随礼</span>
              </p>
            </div>
          </div>
        </div>
        <!-- {{ walletAddress }} -->
      </div>
      <div class="hengfu1" v-else @click="logIn">
        <div class="conner">
          <p class="engBold" v-if="decipherment">CONNECT</p>
          <p class="chinBold" v-else>连接钱包</p>
        </div>
      </div>
    </div>
    <div class="title_big engBold">
      <p v-if="decipherment" class="engBold">MY PEKING MONSTERS</p>
      <p v-else class="chinBold">我的北海怪兽</p>
    </div>
    <div
      class="text_big"
      :class="selectPage == 0 ? 'text_big_b1' : 'text_big_b2'"
    >
      <div class="select">
        <div class="select_left" @click="select_page(0)">
          <p
            :class="selectPage == 0 ? 'select_leftpco1' : 'select_leftpco'"
            class="select_leftp engBold"
            v-if="decipherment"
          >
            SOBER
          </p>
          <p
            :class="selectPage == 0 ? 'select_leftpco1' : 'select_leftpco'"
            v-else
            class="select_leftp chinBold"
          >
            清醒着
          </p>
          <p
            :class="selectPage == 0 ? 'select_leftpco1' : 'select_leftpco'"
            v-if="decipherment"
            class="select_leftp1 engBold"
          >
            (Unstaked)
          </p>
          <p
            :class="selectPage == 0 ? 'select_leftpco1' : 'select_leftpco'"
            v-else
            class="select_leftp1 chinBold"
          >
            (未质押)
          </p>
        </div>
        <div class="select_left" @click="select_page(1)">
          <p
            :class="selectPage == 1 ? 'select_leftpco1' : 'select_leftpco'"
            v-if="decipherment"
            class="select_leftp engBold"
          >
            DRUNK
          </p>
          <p
            :class="selectPage == 1 ? 'select_leftpco1' : 'select_leftpco'"
            v-else
            class="select_leftp chinBold"
          >
            醉酒中
          </p>
          <p
            :class="selectPage == 1 ? 'select_leftpco1' : 'select_leftpco'"
            v-if="decipherment"
            class="select_leftp1 engBold"
          >
            (Staked)
          </p>
          <p
            :class="selectPage == 1 ? 'select_leftpco1' : 'select_leftpco'"
            v-else
            class="select_leftp1 chinBold"
          >
            (已质押)
          </p>
        </div>
      </div>
      <!-- <div class="line">
        <img
          v-if="selectPage == 0"
          src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/xianred.png"
          alt=""
          srcset=""
        />
        <img
          v-if="selectPage == 1"
          src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/xianbiue.png"
          alt=""
          srcset=""
        />
      </div> -->
      <div
        class="unstake_box"
        id="unstake_box"
        v-show="selectPage == 0"
        @scroll="handleScroll($event)"
      >
        <ul class="stake_list" v-if="ListStar != ''">
          <li
            class="stake_li1"
            v-for="(item, key) in ListStar"
            :key="key"
            @click="stakeLI(item)"
          >
            <div class="choose" v-if="item.choose">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/choose.png"
                alt=""
              />
            </div>
            <div class="stake_logo">
              <img :src="item.target_info.url.url" alt="" srcset="" />
            </div>
            <div class="del1 engreg">#{{ item.token_id }}</div>
          </li>
        </ul>
        <div class="kong" v-else>
          <p v-if="decipherment" class="engreg">You have no Peking Monsters</p>
          <p v-else class="chinreg">啪，一个北海怪兽也没有</p>
        </div>
      </div>
      <div
        class="staked_box"
        id="staked_box"
        v-show="selectPage == 1"
        ref="wiaodh"
        @scroll="handleScroll1($event)"
      >
        <ul class="stake_list" v-if="ListStared != ''">
          <li
            class="stake_li"
            v-for="(item, key) in ListStared"
            :key="key"
            @click="stakeLI(item)"
          >
            <div class="choose" v-if="item.choose">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/choose.png"
                alt=""
              />
            </div>
            <div class="stake_logo">
              <img :src="item.target_info.url.url" alt="" srcset="" />
            </div>
            <div class="del">
              <div class="del_id_s">
                <p class="del_id engreg">#{{ item.token_id }}</p>
              </div>
              <div class="del_de engreg">
                <p v-if="decipherment" class="engreg">
                  Drunk for {{ item.since_created }} Days
                </p>
                <p v-else class="chinreg">已醉酒 {{ item.since_created }} 天</p>
              </div>
              <div class="del_de engreg">
                <p v-if="decipherment" class="engreg">
                  Beer Caps: {{ item.beer_caps }}
                </p>
                <p v-else class="chinreg">总瓶盖：{{ item.beer_caps }}</p>
              </div>
            </div>
            <div class="delM">
              <div class="del_id_s">
                <p class="del_id engreg">#{{ item.token_id }}</p>
              </div>
              <div class="del_de engreg">
                <p v-if="decipherment" class="engreg">
                  Drunk for <br />{{ item.since_created }} Days
                </p>
                <p v-else class="chinreg">已醉酒 {{ item.since_created }} 天</p>
              </div>
              <div class="del_de engreg">
                <p v-if="decipherment" class="engreg">
                  Beer Caps:<br />
                  {{ item.beer_caps }}
                </p>
                <p v-else class="chinreg">总瓶盖：{{ item.beer_caps }}</p>
              </div>
            </div>
          </li>
        </ul>
        <div class="kong" v-else>
          <p v-if="decipherment" class="engreg">You have no drunk Monsters</p>
          <p v-else class="chinreg">你没有醉酒中的北海怪兽，去放倒他们吧</p>
        </div>
      </div>
      <div v-if="selectPage == 0">
        <div class="select_stake" v-if="ListStar != ''">
          <div class="stake_btn engBold" @click="btnstake">
            <p v-if="decipherment" class="engBold">GET DRUNK</p>
            <p v-else class="chinBold">开始醉！</p>
          </div>
          <div class="stake_btnall engBold" @click="btn_all">
            <p v-if="decipherment" class="engBold">GET DRUNK, Y'ALL</p>
            <p v-else class="chinBold">全部醉倒</p>
          </div>
        </div>
        <div class="select_stake" v-else>
          <div class="stake_btn engBold" style="color: #cc921f">
            <p v-if="decipherment" class="engBold">GET DRUNK</p>
            <p v-else class="chinBold">开始醉！</p>
          </div>
          <div class="stake_btnall engBold" style="color: #7bc220">
            <p v-if="decipherment" class="engBold">GET DRUNK, Y'ALL</p>
            <p v-else class="chinBold">全部醉倒</p>
          </div>
        </div>
      </div>
      <div class="redeem_btn engBold" v-if="selectPage == 1">
        <div v-if="ListStared != ''" @click="redeem">
          <p v-if="decipherment" class="engBold">GET SOBER</p>
          <p v-else class="chinBold">想戒酒</p>
        </div>
        <div v-else style="color: #cc921f">
          <p v-if="decipherment" class="engBold">GET SOBER</p>
          <p v-else class="chinBold">想戒酒</p>
        </div>
      </div>
    </div>
    <div class="lie">
      <div class="lie_box">
        <p class="hover_img" v-if="connected">
          <img
            class="lie_img lie_yes"
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/boozelotto.png"
            alt=""
            @click="boozelotto"
          />
          <img
            class="lie_img lie_no"
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/boozelotto_hover.png"
            alt=""
            @click="boozelotto"
          />
        </p>
        <p class="hover_img" v-else>
          <img
            class="lie_img lie_yes"
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/boozelotto.png"
            alt=""
            @click="logIn(1)"
          />
          <img
            class="lie_img lie_no"
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/boozelotto_hover.png"
            alt=""
            @click="logIn(1)"
          />
        </p>
        <div class="lie_title">
          <p v-if="decipherment" class="engBold">BOOZELOTTO</p>
          <p v-else class="chinBold">老虎机</p>
        </div>
        <div class="lie_text">
          <p v-if="decipherment" class="engreg">GO CHUN YOU</p>
          <p v-else class="chinreg">去春游</p>
        </div>
      </div>
      <div class="lie_box">
        <p>
          <img
            class="lie_img"
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/macnine.png"
            alt=""
          />
        </p>
        <div class="lie_title">
          <p v-if="decipherment" class="engBold">TIME MACHINE</p>
          <p v-else class="chinBold">时光机</p>
        </div>
        <div class="lie_text">
          <p v-if="decipherment" class="engreg">coming soon...</p>
          <p v-else class="chinreg">马上就到...</p>
        </div>
      </div>

      <div class="lie_box">
        <p>
          <img
            class="lie_img"
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/more.png"
            alt=""
          />
        </p>
        <div class="lie_title engBold">
          <p v-if="decipherment" class="engBold">MERCH N MORE</p>
          <p v-else class="chinBold">周边市集</p>
        </div>
        <div class="lie_text">
          <p v-if="decipherment" class="engreg">coming soon...</p>
          <p v-else class="chinreg">马上就到...</p>
        </div>
      </div>
    </div>
    <!-- 质押进度框 -->
    <div class="schedule" v-show="schedule">
      <div class="schedule_box stakeBox">
        <img
          class="close"
          src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/close.png"
          alt=""
          srcset=""
          @click="close"
        />
        <div class="title" v-if="stake_totally == false">
          <p v-if="decipherment" class="engBold">
            GRADUALLY GETTING <br />DRUNK...
          </p>
          <p v-else class="chinBold">在慢慢醉...</p>
        </div>
        <div class="one" v-if="stake_totally == false">
          <div
            :class="
              stakeone == 0 ? 'number2' : stakeone == 1 ? 'number1' : 'number'
            "
          >
            <p class="engBold">1</p>
            <div class="number_one"></div>
          </div>
          <div class="number_text engreg">
            <p v-if="decipherment" class="engreg">Checking wallet status...</p>
            <p v-else class="chinreg">正在确认钱包状态...</p>
          </div>
        </div>
        <div
          v-if="stake_totally == false"
          :class="stakeshuone ? 'shu1' : 'shu'"
        ></div>
        <div class="one" v-if="stake_totally == false">
          <div
            :class="
              staketwo == 0 ? 'number2' : staketwo == 1 ? 'number1' : 'number'
            "
          >
            <p class="engBold">2</p>
            <div class="number_one"></div>
          </div>
          <div class="number_text engreg">
            <p v-if="decipherment" class="engreg">Checking proof status...</p>
            <p v-else class="chinreg">正在检查质押合约授权</p>
          </div>
        </div>
        <div
          v-if="stake_totally == false"
          :class="stakeshutwo ? 'shu1' : 'shu'"
        ></div>
        <div class="one" v-if="stake_totally == false">
          <div
            :class="
              stakethree == 0
                ? 'number2'
                : stakethree == 1
                ? 'number1'
                : 'number'
            "
          >
            <p class="engBold">3</p>
            <div class="number_one"></div>
          </div>
          <div class="number_text engreg">
            <p v-if="decipherment" class="engreg">You're almost drunk...</p>
            <p v-else class="chinreg">真的快醉了，搞快点</p>
          </div>
        </div>
        <div class="wrong" v-if="stake_totally == true">
          <p>
            <img
              v-if="stake_totallycha"
              class="duiimg"
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/dui.png"
              alt=""
            />
            <img
              v-else
              class="duiimg"
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/cuo.png"
              alt=""
            />
          </p>
          <div>
            <div class="wrong_text engreg" v-if="stake_totallycha">
              <p v-if="decipherment" class="engreg">Totally drunk!</p>
              <p v-else class="chinreg">嗝，我我我，醉了</p>
            </div>
            <div class="wrong_text engreg" v-else>
              <p v-if="decipherment" class="engreg">Oops. Still sober.</p>
              <p v-else class="chinreg">醉酒失败，是假酒！</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 取回进度框 -->
    <div class="schedule" v-show="redschedule">
      <div class="schedule_box redeemBox">
        <img
          class="close"
          src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/close.png"
          alt=""
          srcset=""
          @click="redclose"
        />
        <div class="title engBold" v-if="redeem_totally == false">
          <p v-if="decipherment" class="engBold">Getting sober...</p>
          <p v-else class="chinBold">醒酒中...</p>
        </div>
        <div class="one" v-if="redeem_totally == false">
          <div
            :class="
              redone == 0 ? 'number2' : redone == 1 ? 'number1' : 'number'
            "
          >
            <p class="engBold">1</p>
            <div class="number_one"></div>
          </div>
          <div class="number_text engreg">
            <p v-if="decipherment" class="engreg">Checking wallet status...</p>
            <p v-else class="chinreg">正在确认钱包状态...</p>
          </div>
        </div>
        <div
          v-if="redeem_totally == false"
          :class="redshuone ? 'shu1' : 'shu'"
        ></div>
        <div class="one" v-if="redeem_totally == false">
          <div
            :class="
              redtwo == 0 ? 'number2' : redtwo == 1 ? 'number1' : 'number'
            "
          >
            <p class="engBold">2</p>
            <div class="number_one"></div>
          </div>
          <div class="number_text engreg">
            <p v-if="decipherment" class="engreg">Here's to...sobriety...</p>
            <p v-else class="chinreg">在醒酒了，慢慢来</p>
          </div>
        </div>
        <div class="wrong" v-if="redeem_totally == true">
          <p>
            <img
              v-if="redeem_totallycha"
              class="duiimg"
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/dui.png"
              alt=""
            />
            <img
              v-else
              class="duiimg"
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/cuo.png"
              alt=""
            />
          </p>
          <div>
            <div class="wrong_text" v-if="redeem_totallycha">
              <p v-if="decipherment" class="engreg">You're sober af now.</p>
              <p v-else class="chinreg">醒了，真TM无聊</p>
            </div>
            <div class="wrong_text" v-else>
              <p v-if="decipherment" class="engreg">Nope. Still drunk.</p>
              <p v-else class="chinreg">醒酒失败，还是醉着好</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 中奖名单 -->
    <div class="boozelotto" v-show="booze">
      <!-- 未开奖 -->
      <div class="raffle" v-if="!lottery">
        <div class="raffle_text">
          <p class="engBold" v-if="decipherment">DRAWING IN {{ endTime }}</p>
          <p class="chinBold" v-else>距离开奖还有：{{ endTime }}</p>
        </div>
        <div class="participate" @click="participate">
          <p class="engreg" v-if="decipherment">GO CHUN YOU!!!</p>
          <p class="chinreg" v-else>去春游！</p>
        </div>
        <img
          @click="close_raffle"
          class="close"
          src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/close.png"
          alt=""
        />
        <img
          @click="rule"
          class="hint"
          src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/hint.png"
          alt=""
        />
      </div>
      <!-- 已开奖 -->
      <div class="raffle" v-if="lottery">
        <div class="raffle_text1">
          <span class="engBold" v-if="decipherment">FINISHED</span>
          <span class="chinBold" v-else>开奖结束</span>
          <img
            @click="record"
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/record.png"
            alt=""
          />
        </div>
        <div class="name">
          <p>
            <img
              v-if="winn == 1"
              class="name_img"
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/robot.png"
              alt=""
            />
            <img
              v-if="winn == 0"
              class="name_img"
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/robot_no.png"
              alt=""
            />
          </p>
          <div class="name_text" v-if="winn == 0">
            <p class="engreg" v-if="decipherment">Yeah...Nah.</p>
            <p class="chinreg" v-else>谢谢惠顾。</p>
          </div>
          <div class="name_text1" v-if="winn == 1">
            <p class="engreg" v-if="decipherment">
              Congrats! You lucky monster!<br />Create a ticket in DC now!
            </p>
            <p class="chinreg" v-else>
              摇滚人不骗摇滚人，<br />你！中！奖！了！去DC开个票儿吧！
            </p>
          </div>
        </div>
        <img
          @click="close_raffle"
          class="close"
          src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/close.png"
          alt=""
        />
        <img
          @click="rule"
          class="hint"
          src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/hint.png"
          alt=""
        />
      </div>
      <div
        class="rafflename"
        :class="
          booze_text == 0 ? 'awards1' : booze_text == 1 ? 'awards2' : 'awards3'
        "
      >
        <div class="awards_box1" @click="boozelotto_page(0)">
          <p class="engreg title_pc" v-if="decipherment">Drunk with Pass</p>
          <p class="engreg title_mm" v-if="decipherment">Pass</p>
          <p class="chinreg" v-else>PASS奖池</p>
        </div>
        <div class="awards_box1" @click="boozelotto_page(1)">
          <p class="engreg title_pc" v-if="decipherment">Drunk with Signature</p>
          <p class="engreg title_mm" v-if="decipherment">Signature</p>
          <p class="chinreg" v-else>签名奖池</p>
        </div>
        <div class="awards_box1" @click="boozelotto_page(2)">
          <p class="engreg" v-if="decipherment">Just Drunk</p>
          <p class="chinreg" v-else>只是醉了奖池</p>
        </div>
        <!-- 未开奖 -->
        <div v-if="!lottery">
          <div class="awards_box1_del" v-if="booze_text == 0">
            <div class="one" v-for="(item, key) in pass_pool" :key="key">
              <p class="img_logo">
                <img :src="item.cover.thumbnail_url" alt="" />
              </p>
              <div class="text_id">
                <p class="engreg" v-if="decipherment">
                  {{ item.name }}
                </p>
                <p class="chinreg" v-else>{{ item.name_cn }}</p>
              </div>
            </div>
          </div>
          <div class="awards_box1_del" v-if="booze_text == 1">
            <div class="one" v-for="(item, key) in sign_pool" :key="key">
              <p class="img_logo">
                <img :src="item.cover.thumbnail_url" alt="" />
              </p>
              <div class="text_id">
                <p class="engreg" v-if="decipherment">
                  {{ item.name }}
                </p>
                <p class="chinreg" v-else>{{ item.name_cn }}</p>
              </div>
            </div>
          </div>
          <div class="awards_box1_del" v-if="booze_text == 2">
            <div class="one" v-for="(item, key) in pool" :key="key">
              <p class="img_logo">
                <img :src="item.cover.thumbnail_url" alt="" />
              </p>
              <div class="text_id">
                <p class="engreg" v-if="decipherment">
                  {{ item.name }}
                </p>
                <p class="chinreg" v-else>{{ item.name_cn }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 已开奖 -->
        <div class="awards_lottery" v-if="lottery">
          <div class="title" v-if="booze_text == 0">
            <p class="engBold" v-if="decipherment">Drunkest List</p>
            <p class="chinBold" v-else>人民"KE"金奖池 中奖名单</p>
          </div>
          <div class="title" v-if="booze_text == 1">
            <p class="engBold" v-if="decipherment">Drunker List</p>
            <p class="chinBold" v-else>人民签名奖池 中奖名单</p>
          </div>
          <div class="title" v-if="booze_text == 2">
            <p class="engBold" v-if="decipherment">Drunk List</p>
            <p class="chinBold" v-else>人民公共奖池 中奖名单</p>
          </div>
          <div class="title_name">
            <div class="title_name_text4">
              <p class="engBold" v-if="decipherment">Prize</p>
              <p class="chinBold" v-else>奖品</p>
            </div>
            <div class="title_name_text3">
              <p class="engBold" v-if="decipherment">Wallet Address</p>
              <p class="chinBold" v-else>钱包地址</p>
            </div>
          </div>
          <div v-if="booze_text == 0">
            <div
              class="title_name chinBold"
              v-for="(item, key) in drunkest"
              :key="key"
            >
              <p class="title_name_text">
                <img class="img" :src="item.cover.thumbnail_url" alt="" />
                <span class="span engreg" v-if="decipherment">{{
                  item.name
                }}</span>
                <span class="span chinreg" v-else>{{ item.name_cn }}</span>
              </p>
              <p class="title_name_text1 engreg">
                {{
                  item.winner_address.substr(0, 5) +
                  "****" +
                  item.winner_address.substr(-4)
                }}
              </p>
            </div>
          </div>
          <div v-if="booze_text == 1">
            <div class="title_name" v-for="(item, key) in drunker" :key="key">
              <p class="title_name_text">
                <img class="img" :src="item.cover.thumbnail_url" alt="" />
                <span class="span engreg" v-if="decipherment">{{
                  item.name
                }}</span>
                <span class="span chinreg" v-else>{{ item.name_cn }}</span>
              </p>
              <p class="title_name_text1 engreg">
                {{
                  item.winner_address.substr(0, 5) +
                  "****" +
                  item.winner_address.substr(-4)
                }}
              </p>
            </div>
          </div>
          <div v-if="booze_text == 2">
            <div
              class="title_name chinBold"
              v-for="(item, key) in drunk"
              :key="key"
            >
              <p class="title_name_text">
                <img class="img" :src="item.cover.thumbnail_url" alt="" />
                <span class="span engreg" v-if="decipherment">{{
                  item.name
                }}</span>
                <span class="span chinreg" v-else>{{ item.name_cn }}</span>
              </p>
              <p class="title_name_text1 engreg">
                {{
                  item.winner_address.substr(0, 5) +
                  "****" +
                  item.winner_address.substr(-4)
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 参与抽奖 -->
    <div class="boozelotto" v-show="participate_le">
      <!-- <div class="raffle">
        <div class="raffle_text_le">
          <p class="engBold" v-if="decipherment">LOTTERY CHANCE</p>
          <p class="chinBold" v-else>LOTTERY CHANCE</p>
        </div>
        <img
          @click="close_raffle1"
          class="close"
          src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/close.png"
          alt=""
        />
      </div> -->
      <div class="participate_box">
        <img
          @click="close_raffle1"
          class="close"
          src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/close.png"
          alt=""
        />
        <div class="title_big">
          <p class="engBold" v-if="decipherment">Tickets for Chun You</p>
          <p class="chinBold" v-else>春游门票（2日通票）</p>
        </div>
        <!-- <div class="title_sam">
          <p class="engreg" v-if="decipherment">
            Redeem Your Sweepstakes Chance
          </p>
          <p class="chinreg" v-else>Redeem Your Sweepstakes Chance</p>
        </div> -->
        <div class="maa participate_body">
          <div class="name">
            <p class="engreg" v-if="decipherment">Total draws</p>
            <p class="chinreg" v-else>总抽奖次数</p>
          </div>
          <div class="name_del engreg">{{ totle_time }}</div>
        </div>
        <div class="participate_body maaed">
          <div class="name">
            <p class="engreg" v-if="decipherment">My draws</p>
            <p class="chinreg" v-else>我的抽奖次数</p>
          </div>
          <div class="name_del engreg">{{ my_totle }}</div>
        </div>
        <div class="participate_body maaed">
          <div class="name">
            <p class="engreg" v-if="decipherment">Beer Caps per Draw</p>
            <p class="chinreg" v-else>单次瓶盖数</p>
          </div>
          <div class="name_del engreg">
            <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/worldCup/cap.png" alt="" />
            <p>{{ price_lorr }}</p>
          </div>
        </div>
        <div class="participate_body1">
          <div class="name">
            <p class="engreg" v-if="decipherment">Draws</p>
            <p class="chinreg" v-else>我的抽奖次数</p>
          </div>
          <div class="name_sel engreg" @click="selDel">
            <p class="sel_number">{{ sel_number }}</p>
            <img class="xia" src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/xia.png" alt="" />
            <div class="sel_del" v-show="sel_delk">
              <div class="sel_delbox">
                <p
                  class="sel_delnum"
                  v-for="(item, key) in 20"
                  :key="key"
                  @click.stop="sel_delNum(item)"
                >
                  <span>{{ item }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="participate_body maaed">
          <div class="name">
            <p class="engreg" v-if="decipherment">Total</p>
            <p class="chinreg" v-else>总瓶盖数</p>
          </div>
          <div class="name_del engreg">
            <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/worldCup/cap.png" alt="" />
            <p>{{ price_lorr * sel_number }}</p>
          </div>
        </div>
        <div class="mycap">
          <div class="mycap_name">
            <p class="engreg" v-if="decipherment">My beer caps:</p>
            <p class="chinreg" v-else>我的瓶盖：</p>
          </div>
          <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/worldCup/cap.png" alt="" />
          <div class="mycap_number engBold">{{ beer_caps }}</div>
        </div>
        <div
          class="participate_buy"
          @click="participateBuy"
          v-show="participate_buy"
        >
          <p class="engBold" v-if="decipherment">Go!</p>
          <p class="chinBold" v-else>抽！</p>
        </div>
        <div class="participate_buyed" v-show="!participate_buy">
          <p class="engBold" v-if="decipherment">Go!</p>
          <p class="chinBold" v-else>抽！</p>
        </div>
      </div>
    </div>
    <!-- 中奖名单历史记录 -->
    <div class="boozelotto_his" v-show="boozed">
      <div class="boozed_his">
        <img
          @click="close_record"
          class="close"
          src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/close.png"
          alt=""
        />
        <div class="title">
          <p class="engBold" v-if="decipherment">Winner History</p>
          <p class="chinBold" v-else>历史中奖名单</p>
        </div>
        <div class="his_box">
          <div class="title_name chinBold">
            <div class="title_name_text4">
              <p class="engBold" v-if="decipherment">Prize</p>
              <p class="chinBold" v-else>奖品</p>
            </div>
            <div class="title_name_text3">
              <p class="engBold" v-if="decipherment">Wallet Address</p>
              <p class="chinBold" v-else>钱包地址</p>
            </div>
          </div>
          <div
            class="title_name"
            v-for="(item, key) in history_listed"
            :key="key"
          >
            <p class="title_name_text">
              <img class="img" :src="item.cover.thumbnail_url" alt="" />
              <span class="span engreg" v-if="decipherment">{{
                item.name
              }}</span>
              <span class="span chinreg" v-else>{{ item.name_cn }}</span>
            </p>
            <p class="title_name_text1 engreg">
              {{
                item.winner_address.substr(0, 5) +
                "****" +
                item.winner_address.substr(-4)
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 中奖规则 -->
    <div class="boozelotto_his1" v-show="ruled">
      <div class="boozed_his">
        <img
          @click="close_record"
          class="close"
          src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/close.png"
          alt=""
        />
        <div class="title">
          <p class="engBold" v-if="decipherment">RULES</p>
          <p class="chinBold" v-else>抽奖规则</p>
        </div>
        <div class="text">
          <p class="engreg" v-if="decipherment">
            1. Stakers only! Automatic entrance！
          </p>
          <p class="chinreg" v-else>
            1. 醉酒中的滚子们什么也不用做,凭瓶盖自动参与抽奖。
          </p>
        </div>
        <div class="text">
          <p class="engreg" v-if="decipherment">
            2. 3 raffle pools available: Drunk with Pass, Drunk with Signature, Just Drunk.
          </p>
          <p class="chinreg" v-else>
            2. 每个瓶盖平分所有中奖几率，也就是说，瓶盖越多，中奖概率越高。
          </p>
        </div>
        <div class="text">
          <p class="engreg" v-if="decipherment">
            3. More Beer Caps = Bigger chance of winning!
          </p>
          <p class="chinreg" v-else>
            3. 三个奖池：PASS奖池，签名奖池，只是醉了奖池
          </p>
        </div>
      </div>
    </div>
    <!-- 转移瓶盖 -->
    <div class="thansfer" v-show="thansfer_true">
      <div class="thansfer_box">
        <img
          @click="close_thansfer"
          class="close"
          src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/close.png"
          alt=""
        />
        <div v-if="sussce">
          <p>
            <img
              class="dui"
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/dui.png"
              alt=""
            />
          </p>
          <div class="successful">
            <p v-if="decipherment" class="engreg">Transfer Successful！</p>
            <p v-else class="chinreg">转移成功！</p>
          </div>
        </div>
        <div v-else>
          <div class="title">
            <p v-if="decipherment" class="engBold">
              Yohann, congratulations <br />
              on your marriage!
            </p>
            <p v-else class="chinBold">祝有汉新婚快乐！</p>
          </div>
          <div class="connect_box">
            <div class="connect_left">
              <p v-if="decipherment" class="engreg">You have:</p>
              <p v-else class="chinreg">你有:</p>
            </div>
            <div class="connect_right">
              <p><img src="https://img.ohdat.io/peking_monsters/website/assets/icon/worldCup/cap.png" alt="" /></p>
              <p class="number">{{ beer_caps }}</p>
            </div>
          </div>
          <div class="connect_box connect_top">
            <div class="connect_left">
              <p v-if="decipherment" class="engreg">Transfer to:</p>
              <p v-else class="chinreg">随礼给:</p>
            </div>
            <div class="connect_right">
              <div class="input_box">
                <!-- <input type="text" value="0xd2B7094c390c30EE2bDC8e20a91A7fe633f65B58" readonly="readonly" /> -->
                <input
                  type="text"
                  v-model="items.cap_number"
                  v-on:input="cap_numberChange"
                  readonly="readonly"
                  class="engreg"
                />
              </div>
            </div>
          </div>
          <div class="connect_box connect_top">
            <div class="connect_left">
              <p v-if="decipherment" class="engreg">Amount:</p>
              <p v-else class="chinreg">数量:</p>
            </div>
            <div class="connect_right">
              <div class="input_box">
                <input
                  type="number"
                  v-model="items.amount_number"
                  v-on:input="valueChange"
                />
                <!-- v-bind:value="items.amount_number"
                  v-on:input="valueChange" -->
              </div>
            </div>
          </div>
          <div
            :class="caps_true ? 'thansfer_submit' : 'thansfer_submited'"
            @click="thansfer_submit(caps_true)"
          >
            <p v-if="decipherment" class="engBold">SUBMIT</p>
            <p v-else class="chinBold">随礼</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 参与抽奖结果 -->
    <div class="lottery_results" v-show="results">
      <div class="results_box">
        <img
          @click="close_results"
          class="close"
          src="https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/cuo.png"
          alt=""
        />
        <p class="results_img">
          <img :src="resultsImg" alt="" />
        </p>
        <div class="results_name">
          <p class="engBold" v-if="decipherment">{{ results_texte }}</p>
          <p class="chinBold" v-else>{{ results_textc }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  connectWallet,
  getCurrentAccount,
  checkChain,
  isApprovedForAll,
  setApprovalForAll,
  stakeNFT,
  redeemNFT,
  walletSign,
} from "@/utils/login.js";
import {
  list_my_item,
  my_staked_peking_monster_list,
  my_peking_monsters_profile,
  list_all_peking_monsters,
} from "@/api/item/index.js";

import { peking_monsters_raffle_info } from "@/api/goods/index.js";
import { caps_transfer, purchase_history } from "@/api/trade/index.js";

export default {
  name: "Music",
  data() {
    return {
      selectPage: 0,
      ListStar: [],
      ListStared: [],
      items: [],
      itemsed: [],
      itemser: [],
      param: {
        limit: 10,
        page: 1,
      },
      params: {
        limit: 10,
        page: 1,
      },
      walletAddress: "",
      connected: false,
      busy: false,
      schedule: false,
      redschedule: false,
      nftContract: "",
      stakeContract: "",
      staked_count: "",
      ratio: "",
      rate: "",
      beer_caps: "",
      stakeone: 0,
      staketwo: 0,
      stakethree: 0,
      stakeshuone: false,
      stakeshutwo: false,
      redone: 0,
      redtwo: 0,
      redshuone: false,
      hinttr: false,
      revise_text: false,
      chin: "中文",
      eng: "ENGLISH",
      decipherment: true,
      menudel: false,
      unstakeBox: "",
      stakedBox: "",
      stake_totally: false,
      redeem_totally: false,
      stake_totallycha: false,
      redeem_totallycha: false,
      fleg: false,
      flegg: false,
      fff: false,
      ggg: false,
      booze: false,
      boozed: false,
      booze_text: 0,
      lottery: false,
      drunkest: [],
      drunker: [],
      drunk: [],
      history_listed: [],
      endTime: "",
      pass_pool: [],
      sign_pool: [],
      pool: [],
      history_list: [],
      flag: false,
      ruled: false,
      winn: 0,
      currentAccountnum: "",
      thansfer_true: false,
      caps_true: false,
      items: {
        cap_number: "",
        amount_number: "",
      },
      sussce: false,
      participate_le: false,
      sel_number: 1,
      sel_delk: false,
      my_totle: 0,
      totle_time: 0,
      price_lorr: 0,
      participate_buy: false,
      results: false,
      resultsImg: "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/success.png",
      results_textc: "Transfer Successful！",
      results_texte: "Transfer Successful！",
    };
  },
  watch: {
    decipherment: function (oldValue) {
      // this.revise_text= false;
      if (oldValue == true) {
        this.chin = "中文";
        this.eng = "ENGLISH";
      } else {
        this.chin = "ENGLISH";
        this.eng = "中文";
      }
    },
  },
  mounted() {
    let dddd = document.getElementById("unstake_box");
    dddd.style.overflowY = "hidden";
    if (this.$route.params.decipherment) {
      this.decipherment = true;
    } else {
      this.decipherment = false;
    }
    this.preload();
    this.watchWeb3Event();
    this.checkLogIn();
    this.MyProfile();
    this.param.page = 1;
    this.ListStar = [];
    this.params.page = 1;
    this.ListStared = [];
    // this.ListMyItem();
    // this.ListMyStaked();
  },
  methods: {
    preload() {
      let imgs = [
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/logo.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/logo.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/xia.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/opensea1.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/twitter1.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/redirect.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/logo.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/menu.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/openseaM.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/twitterM.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/redirect.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/openseaM.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/twitterM.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/redirect.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/banner.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/hi.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/hi1.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/xing.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/wenhao.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/xianred.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/xianbiue.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/choose.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/choose.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/boozelotto.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/boozelotto_hover.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/boozelotto.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/boozelotto_hover.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/macnine.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/more.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/close.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/dui.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/cuo.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/close.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/dui.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/cuo.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/close.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/hint.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/record.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/robot.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/robot_no.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/close.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/hint.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/close.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/close.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/worldCup/cap.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/xia.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/worldCup/cap.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/worldCup/cap.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/close.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/close.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/close.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/dui.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/worldCup/cap.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/cuo.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/success.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/success.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/error.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/connect.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/mintbutton.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/mintbutton.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/connect.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/check.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/hoverbox.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/hengfu.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/stake.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/hint.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/hengfu.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/stake.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/frame.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/frame1.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/detail.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/detail1.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/stake.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/stake_all.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/stake.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/schedule.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/raffle.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/detail.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/participate.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/sel.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/sel_p.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/detail_ye.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/detail_no.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/awards1.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/awards2.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/awards3.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/awards.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/awards.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/thansfer_box.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/staked_choose.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/unstaked_choose.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/succ.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/connect.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/hoverbox.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/connect.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/mintbutton.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/mintbutton.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/connect.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/check.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/hoverbox.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/connect.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/mintbutton.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/mintbutton.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/but.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/but.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/but.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/hoverbox.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/hoverbox.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/but.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/mebudel.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/hengfu1.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/hint1.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/hengfu2.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/stake.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/frame1M.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/frameM.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/staked_choose.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/detail2.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/detail1.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/stake.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/stake_all.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/stake.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/schedule.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/raffle.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/detail.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/participate_m.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/sel.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/sel_p.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/detail_ye.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/detail_no.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/awards1.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/awards2.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/awards3.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/awards.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/winning/awards.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/thansfer_box.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/staked_choose.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/unstaked_choose.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/succ.png",
      ]
      for (let img of imgs) {
        let image = new Image()
        image.src = img
        image.onload = () => {
          this.preloadCount++
          // 计算图片加载的百分数，绑定到percent变量
          let percentNum = Math.floor(this.preloadCount / 14 * 100)
          this.percent = `${percentNum}%`
        }
      }
    },
    close_results() {
      this.raffle_info();
      this.MyProfile();
      this.results = false;
      this.booze = true;
    },
    async participateBuy() {
      this.sel_delk = false;
      this.participate_buy = false;
      const params = {
        num: Number(this.sel_number),
      };
      let res = await purchase_history(params);
      console.log(res);
      if (res.code == 1101) {
        try {
          await this.mintSign();
          this.participateBuy();
        } catch (error) {
          this.participate_buy = true;
        }
      } else if (res.code == 200) {
        this.results = true;
        this.participate_le = false;
        this.resultsImg = "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/success.png";
        this.results_textc = "你的瓶盖已进入奖池";
        this.results_texte = "You've entered the raffle. Good luck rocker!";
      } else {
        this.results = true;
        this.participate_le = false;
        this.resultsImg = "https://img.ohdat.io/peking_monsters/website/assets/icon/pledge/error.png";
        this.results_textc = "什么？抽奖失败！";
        this.results_texte = "Oops. Raffle failed. Re-enter now?";
      }
    },
    selDel() {
      this.sel_delk = !this.sel_delk;
    },
    sel_delNum(e) {
      console.log(e);
      this.sel_number = e;
      if (this.sel_number * this.price_lorr > this.beer_caps) {
        this.participate_buy = false;
      } else {
        this.participate_buy = true;
      }
      this.sel_delk = false;
    },
    participate() {
      this.booze = false;
      this.participate_le = true;
      if (this.sel_number * this.price_lorr > this.beer_caps) {
        this.participate_buy = false;
      } else {
        this.participate_buy = true;
      }
    },
    close_raffle1() {
      this.booze = true;
      this.participate_le = false;
    },
    async mintSign(e) {
      let currentAccount = await getCurrentAccount();
      this.currentAccounttext = currentAccount;
      if (currentAccount == null) {
        await connectWallet();
        this.connected = true;
      }
      let newToken = await walletSign(currentAccount);
      console.log(newToken);
      if (newToken) {
        localStorage.setItem("token_" + currentAccount, newToken);
      }
    },
    async thansfer_submit(e) {
      if (e) {
        console.log(this.items.amount_number);
        console.log(this.items.cap_number);
        const params = {
          caps: Number(this.items.amount_number),
          to_wallet_address: "0xd2B7094c390c30EE2bDC8e20a91A7fe633f65B58",
        };
        let res = await caps_transfer(params);
        console.log(res);
        if (res.code == 1101) {
          await this.mintSign();
          this.thansfer_submit(e);
        } else if (res.code == 200) {
          await this.MyProfile();
          this.sussce = true;
        }
      }
    },
    valueChange(event) {
      this.items.amount_number = event.target.value;
      this.items.amount_number = this.items.amount_number.replace(/\D/g, "");
      this.items.amount_number =
        this.items.amount_number == 0 ? "" : this.items.amount_number;
      this.duibi();
    },
    cap_numberChange(event) {
      this.items.cap_number = event.target.value;
      this.duibi();
    },
    duibi() {
      // &&
      //   /^0x/.test(this.items.cap_number) &&
      //   /^(?=[\s\S]{42,42}$)[a-zA-Z0-9]*[^$%^&*;:,<>?()\"']*$/.test(
      //     this.items.cap_number
      //   )
      if (
        this.beer_caps >= this.items.amount_number &&
        this.items.amount_number != ""
      ) {
        this.caps_true = true;
      } else {
        this.caps_true = false;
      }
    },
    thansfer() {
      this.items.cap_number = "Yohann";
      this.thansfer_true = true;
    },
    record() {
      this.booze = false;
      this.boozed = true;
      this.ruled = false;
    },
    rule() {
      this.ruled = true;
      this.booze = false;
      this.boozed = false;
    },
    close_record() {
      this.booze = true;
      this.boozed = false;
      this.ruled = false;
    },
    close_thansfer() {
      this.thansfer_true = false;
      this.sussce = false;
      this.items.amount_number = "";
    },
    //倒计时
    timeDown() {
      const endTime = new Date(this.start_time);
      const nowTime = new Date();
      let leftTime = parseInt((endTime.getTime() - nowTime.getTime()) / 1000);
      let d = parseInt(leftTime / (24 * 60 * 60));
      let h =
        Number(this.formate(parseInt((leftTime / (60 * 60)) % 24))) +
        Number(d * 24);
      let m = this.formate(parseInt((leftTime / 60) % 60));
      let s = this.formate(parseInt(leftTime % 60));
      if (leftTime <= 0) {
        this.flag = true;
        this.$emit("time-end");
        this.time = `00:00:00`;
        return;
      }
      this.endTime = `${h}:${m}:${s}`;
    },
    formate(time) {
      if (time >= 10) {
        return time;
      } else {
        return `0${time}`;
      }
    },
    async raffle_info() {
      let res = await peking_monsters_raffle_info();
      console.log(res);
      if (res.code == 200) {
        if (res.data.status == 1) {
          this.lottery = false;
          this.pass_pool = res.data.pass_pool;
          this.sign_pool = res.data.sign_pool;
          this.pool = res.data.pool;
          this.history_list = res.data.history_list;
          this.start_time = res.data.start_time;
          let time = setInterval(() => {
            if (this.flag == true) {
              clearInterval(time);
            }
            this.timeDown();
          }, 500);
        } else {
          this.lottery = true;
          this.drunkest = res.data.pass_pool;
          this.drunker = res.data.sign_pool;
          this.drunk = res.data.pool;
          this.history_listed = res.data.history_list;
          this.winn = res.data.win;
        }
        this.my_totle = res.data.raffle;
        this.totle_time = res.data.total_raffle;
        this.price_lorr = res.data.price;
        if (this.sel_number * this.price_lorr > this.beer_caps) {
          this.participate_buy = false;
        } else {
          this.participate_buy = true;
        }
      }
    },
    boozelotto_page(e) {
      this.booze_text = e;
    },
    boozelotto() {
      this.raffle_info();
      this.booze = true;
    },
    close_raffle() {
      this.booze = false;
    },
    deciphermented() {
      this.decipherment = !this.decipherment;
    },
    twitter() {
      window.open("https://twitter.com/Peking_Monsters");
    },
    redirect() {
      window.open("https://discord-verify.pekingmonsters.xyz/v2/redirect");
    },
    openseaview() {
      window.open("https://opensea.io/collection/pekingmonsters");
    },
    menu() {
      this.menudel = !this.menudel;
    },
    party() {
      this.$router.push({
        name: "party",
        params: { decipherment: this.decipherment },
      });
    },
    starry() {
      this.$router.push({
        name: "3DPARTY",
        params: { decipherment: this.decipherment },
      });
    },
    changeCloth() {
      this.$router.push({
        name: "worldCupCloth",
        params: { decipherment: this.decipherment },
      });
    },
    world() {
      this.$router.push({
        name: "worldCup",
        params: { decipherment: this.decipherment },
      });
    },
    index() {
      this.$router.push({
        name: "mint",
        params: { decipherment: this.decipherment },
      });
    },
    refresh() {
      location.reload();
    },
    revise() {
      this.revise_text = !this.revise_text;
    },
    hint() {
      if (window.screen.width < 1024) {
        this.hinttr = !this.hinttr;
      }
    },
    close() {
      this.schedule = false;
      this.param.page = 1;
      this.ListStar = [];
      this.ListMyItem();
      this.MyProfile();
      this.stakeone = 0;
      this.staketwo = 0;
      this.stakethree = 0;
      this.stakeshuone = false;
      this.stakeshutwo = false;
    },
    redclose() {
      this.redschedule = false;
      this.params.page = 1;
      this.ListStared = [];
      this.ListMyStaked();
      this.MyProfile();
      this.redone = 0;
      this.redtwo = 0;
      this.redtshuone = false;
    },
    handleScroll(e) {
      // 获取滚动的内容实际高度（包括超出可视窗的溢出部分）
      var scrollHeight = Math.floor(e.target.scrollHeight);
      //获取滚动条滚动的距离
      var scrollTop = Math.floor(e.target.scrollTop);
      //获取窗口可视范围高度
      var clientHeight = Math.floor(e.target.clientHeight);
      //  可视窗口高度加上滚动的距离大于或者等于的总内容高度的时候触发
      var that = this;
      if (clientHeight + scrollTop >= scrollHeight) {
        setTimeout(() => {
          that.flegg = true;
          if (that.flegg) {
            that.flegg = false;
            if (that.ggg == false) {
              that.ListMyItem();
            }
            that.ggg = false;
          }
        }, 500);
      }
    },
    handleScroll1(e) {
      // 获取滚动的内容实际高度（包括超出可视窗的溢出部分）
      var scrollHeight = Math.floor(e.target.scrollHeight);
      //获取滚动条滚动的距离
      var scrollTop = Math.floor(e.target.scrollTop);
      //获取窗口可视范围高度
      var clientHeight = Math.floor(e.target.clientHeight);
      //  可视窗口高度加上滚动的距离大于或者等于的总内容高度的时候触发
      var that = this;
      if (clientHeight + scrollTop >= scrollHeight) {
        setTimeout(() => {
          that.fleg = true;
          if (that.fleg) {
            that.fleg = false;
            if (that.fff == false) {
              console.log(99999);
              that.ListMyStaked();
            }
            that.fff = false;
          }
        }, 500);
      }
    },
    async MyProfile() {
      let res = await my_peking_monsters_profile();
      if (res.code == 200) {
        this.nftContract = res.data.nft_contract;
        this.stakeContract = res.data.stake_contract;
        this.staked_count = res.data.staked_count;
        this.ratio = res.data.ratio;
        this.rate = res.data.rate;
        this.beer_caps = res.data.beer_caps;
        if (this.sel_number * this.price_lorr > this.beer_caps) {
          this.participate_buy = false;
        } else {
          this.participate_buy = true;
        }
      }
    },
    async ListMyItem() {
      const params = {
        limit: 20,
        page: this.param.page,
        item_type: "peking_monsters",
      };
      let res = await list_my_item(params);
      if (res.code == 200) {
        let list = res.data.list;
        if (list == null) {
          // this.busy = true;
        } else {
          // this.busy = false;
          ++this.param.page;
          list.forEach((i) => {
            this.ListStar.push(i);
          });
        }
      } else {
        this.ListStar = [];

        // this.unstakeBox.style.overflowY = "scroll";hidden
      }
      // console.log(this.ListStar.length);
      let ddd = document.getElementById("unstake_box");

      if (window.screen.width < 1024) {
        if (this.ListStar.length <= 2) {
          ddd.style.overflowY = "hidden";
        } else {
          ddd.style.overflowY = "scroll";
        }
      } else {
        if (this.ListStar.length <= 5) {
          ddd.style.overflowY = "hidden";
        } else {
          ddd.style.overflowY = "scroll";
        }
      }
    },
    async ListMyStaked() {
      const params = {
        limit: 20,
        page: this.params.page,
        item_type: "peking_monsters",
      };
      let res = await my_staked_peking_monster_list(params);
      if (res.code == 200) {
        let list = res.data.list;
        console.log(list);
        if (list != null) {
          // this.busy = false;
          // alert(this.params.page);
          ++this.params.page;
          // alert(this.params.page);
          list.forEach((i) => {
            this.ListStared.push(i);
          });
        }
      } else {
        this.ListStared = [];
      }
      let fff = document.getElementById("staked_box");
      if (window.screen.width < 1024) {
        if (this.ListStared.length <= 2) {
          fff.style.overflowY = "hidden";
        } else {
          fff.style.overflowY = "scroll";
        }
      } else {
        if (this.ListStared.length <= 5) {
          fff.style.overflowY = "hidden";
        } else {
          fff.style.overflowY = "scroll";
        }
      }
    },
    select_page(e) {
      this.selectPage = e;
      if (e == 0) {
        this.param.page = 1;
        this.ListStar = [];
        this.ListMyItem();
        this.MyProfile();
        this.items = [];
      } else {
        // console.log(this.e);
        this.fleg = false;
        this.params.page = 1;
        this.ListStared = [];
        this.ListMyStaked();
        this.MyProfile();
        this.itemsed = [];
      }
    },
    stakeLI(item) {
      if (Object.prototype.hasOwnProperty.call(item, "choose")) {
        item["choose"] = !item["choose"];
      } else {
        this.$set(item, "choose", true);
      }
    },
    async btnstake() {
      var that = this;
      that.stake_totally = false;
      that.stake_totallycha = false;
      that.items = [];
      that.ListStar.forEach((item) => {
        if (item.choose) {
          that.items.push(Number(item.token_id));
        }
        if (Object.prototype.hasOwnProperty.call(item, "choose")) {
          item["choose"] = false;
        } else {
          that.$set(item, "choose", false);
        }
      });
      if (that.items == "") {
        that.$message.error("请选择！");
      } else {
        that.btn(1);
      }
    },
    async btn(i) {
      var that = this;
      that.schedule = true;
      that.stakeone = 1;
      await checkChain();
      // 第一步完成进入第二步
      that.stakeone = 2;
      that.staketwo = 1;
      that.stakeshuone = true;

      if (that.nftContract == "" || that.stakeContract == "") {
        await that.MyProfile();
      }
      var currentAccount = await getCurrentAccount();
      let isApproval = await isApprovedForAll(
        currentAccount,
        that.nftContract,
        that.stakeContract
      );
      if (!isApproval) {
        setApprovalForAll(currentAccount, that.nftContract, that.stakeContract)
          .on("transactionHash", function (hash) {
            console.log("hash", hash);
          })
          .on("confirmation", function (confirmationNumber, receipt) {
            console.log("confirmationNumber", confirmationNumber);
          })
          .on("receipt", function (receipt) {
            console.log(receipt);
            // 第二步完成进入第三步
            that.stakeone = 2;
            that.staketwo = 2;
            that.stakethree = 1;
            that.stakeshuone = true;
            that.stakeshutwo = true;
            that.go_stake(currentAccount, i);
            //fixme
            // alert("授权成功");
          })
          .on("error", function (error, receipt) {
            // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
            console.log(error);
          });
      } else {
        // 第二步完成进入第三步
        that.stakeone = 2;
        that.staketwo = 2;
        that.stakethree = 1;
        that.stakeshuone = true;
        that.stakeshutwo = true;
        that.go_stake(currentAccount, i);
      }
    },
    go_stake(e, i) {
      var that = this;
      var stakelist;
      if (i == 1) {
        stakelist = that.items;
      } else {
        stakelist = that.itemser;
      }
      stakeNFT(e, stakelist, that.stakeContract, function (sender) {
        sender
          .on("transactionHash", function (hash) {
            console.log("hash==", hash);
          })
          .on("confirmation", function (confirmationNumber, receipt) {
            console.log("confirmationNumber", confirmationNumber);
          })
          .on("receipt", function (receipt) {
            console.log("receipt", receipt);
            // 第三步完成
            that.stakeone = 2;
            that.staketwo = 2;
            that.stakethree = 2;
            that.stakeshuone = true;
            that.stakeshutwo = true;
            // that.$message.success(
            //   "Congrats!! You've successfully staked your pandas."
            // );
            that.param.page = 1;
            that.ListStar = [];
            that.stake_totally = true;
            that.stake_totallycha = true;
            var time = setTimeout(() => {
              that.schedule = false;
              clearTimeout(time);
            }, 3000);
            that.ListMyItem();
            that.MyProfile();
            that.stakeone = 0;
            that.staketwo = 0;
            that.stakethree = 0;
            that.stakeshuone = false;
            that.stakeshutwo = false;
          })
          .on("error", function (error, receipt) {
            // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
            console.log(error);
            that.param.page = 1;
            that.ListStar = [];
            that.stake_totally = true;
            that.stake_totallycha = false;
            var time = setTimeout(() => {
              that.schedule = false;
              clearTimeout(time);
            }, 3000);
            that.ListMyItem();
            that.MyProfile();
            that.stakeone = 0;
            that.staketwo = 0;
            that.stakethree = 0;
            that.stakeshuone = false;
            that.stakeshutwo = false;
          });
      });
    },
    async redeem() {
      var that = this;
      that.redeem_totally = false;
      that.redeem_totallycha = false;
      that.itemsed = [];
      that.ListStared.forEach((item) => {
        if (item.choose) {
          that.itemsed.push(Number(item.token_id));
        }
        if (Object.prototype.hasOwnProperty.call(item, "choose")) {
          item["choose"] = false;
        } else {
          that.$set(item, "choose", false);
        }
      });
      if (that.itemsed == "") {
        that.$message.error("请选择！");
      } else {
        that.redschedule = true;
        that.redone = 1;
        await checkChain();
        // 第一步完成进入第二步
        that.redone = 2;
        that.redtwo = 1;
        that.redshuone = true;
        var currentAccount = await getCurrentAccount();
        var that = this;

        redeemNFT(
          currentAccount,
          that.itemsed,
          that.stakeContract,
          function (sender) {
            sender
              .on("transactionHash", function (hash) {
                console.log("hash==", hash);
              })
              .on("confirmation", function (confirmationNumber, receipt) {
                console.log("confirmationNumber", confirmationNumber);
              })
              .on("receipt", function (receipt) {
                console.log("receipt", receipt);

                // that.$message.success(
                //   "Congrats!! You've successfully staked your pandas."
                // );
                that.redone = 2;
                that.redtwo = 2;
                that.redshuone = true;
                that.params.page = 1;
                that.ListStared = [];
                that.redeem_totally = true;
                that.redeem_totallycha = true;
                var time = setTimeout(() => {
                  that.redschedule = false;
                  clearTimeout(time);
                }, 3000);
                // that.redschedule = false;
                that.ListMyStaked();
                that.MyProfile();
              })
              .on("error", function (error, receipt) {
                that.redone = 0;
                that.redtwo = 0;
                that.redshuone = true;
                that.params.page = 1;
                that.ListStared = [];
                that.redeem_totally = true;
                that.redeem_totallycha = false;
                var time = setTimeout(() => {
                  that.redschedule = false;
                  clearTimeout(time);
                }, 3000);
                // that.redschedule = false;
                that.ListMyStaked();
                console.log(888888);
                that.MyProfile();
                // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
                console.log(error);
                // that.$store.dispatch("mask/closeMask", "stepState");
              });
          }
        );
      }
    },
    async btn_all() {
      var that = this;
      that.stake_totally = false;
      that.stake_totallycha = false;
      const params = {
        type: 1,
      };
      let res = await list_all_peking_monsters(params);
      that.itemser = [];
      res.data.forEach((item) => {
        that.itemser.push(Number(item));
      });
      console.log(that.itemser);
      if (that.itemser == "") {
        that.$message.error("请选择！");
      } else {
        that.btn(2);
      }
    },
    async logIn(index) {
      let currentAccount;
      try {
        currentAccount = await getCurrentAccount();
        this.currentAccountnum = currentAccount;
      } catch (error) {
        var herf = window.location.hostname;
        window.open("https://metamask.app.link/dapp/" + herf);
      }
      if (currentAccount == null) {
        await connectWallet();
      }
      this.connected = true;
      console.log(index);
      if (index == 1) {
        this.boozelotto();
      }
      // this.ListMyItem();
    },
    async checkLogIn() {
      let currentAccount = await getCurrentAccount();
      this.currentAccountnum = currentAccount;
      // console.log(this.currentAccountnum);
      if (currentAccount != undefined) {
        // this.ListMyItem();
        this.walletAddress =
          currentAccount.substr(0, 5) + "****" + currentAccount.substr(-4);
      }
      if (currentAccount) {
        this.connected = true;
        this.param.page = 1;
        this.ListStar = [];
        this.ListMyItem();
        this.MyProfile();
        if (localStorage.getItem("wallet_address") != currentAccount) {
          localStorage.setItem("wallet_address", currentAccount);
        }
      }
    },
    watchWeb3Event() {
      var that = this;
      ethereum.on("disconnect", (res) => {
        console.log("已断开网络", res);
      });
      ethereum.on("connect", function (res) {
        console.log("已连接到网络", res);
      });
      ethereum.on("accountsChanged", function (res) {
        localStorage.setItem("wallet_address", res[0]);
        that.currentAccountnum = res[0];
        // that.params.page = 1;
        that.fff = true;
        that.ggg = true;
        that.select_page(that.selectPage);
        // that.param.page = 1;
        // that.ListStar = [];
        // that.params.page = 1;
        // that.ListStared = [];
        // that.ListMyItem();
        // console.log(444444);
        // that.ListMyStaked();
        // console.log(that.params.page);
        // console.log(that.ListStared);
        that.MyProfile();
        that.sel_number = 1;
        that.sel_delk = false;
        that.raffle_info();
        if (res[0]) {
          that.walletAddress = res[0].substr(0, 5) + "****" + res[0].substr(-4);
          // that.items.cap_number = "0xd2B7094c390c30EE2bDC8e20a91A7fe633f65B58";
          that.items.amount_number = "";
          that.thansfer_true = false;
          that.caps_true = false;
          that.connected = true;
        } else {
          that.connected = false;
          that.walletAddress = "";
        }
      });
      ethereum.on("chainChanged", function (res) {
        const network = parseInt(res, 16);
        console.log(`networkID`, res, network);
        that.$store
          .dispatch("web3/set_network", network)
          .then((res) => {
            if (
              network == process.env.VUE_APP_MAIN_NETWORK ||
              network == process.env.VUE_APP_MAIN_NETWORK2
            ) {
              that.$store.dispatch("mask/closeMask", "mainNetwork");
              //  this.connectMetaMask()
              console.log("已切换到连接主网络");
              // window.location.reload();
            } else {
              that.$store.dispatch("mask/openMask", "mainNetwork");
            }
          })
          .catch((err) => {});
      });
      ethereum.on("message", (msg) => console.log(msg, 11111));
      // alert("成功");
    },
  },
};
</script>

<style src="./index.scss" lang="scss" scoped></style>
<style src="./index.mobile.scss" lang="scss" scoped></style>
